<template>
  <div>
    <!-- pc -->
    <div class="pc container">
      <div class="h5 main" style="padding-top:60px;padding-bottom:8px">퀵빌더 임시저장 서비스</div>
      <card-temporary-pc v-for="(service, s_idx) in computedList"
                      :key="`service-${s_idx}`" :card="service"
                      @create="clickCreate(service)"
                      @edit="step=> clickEdit(service, step)"
                      @delete="clickDelete(service, s_idx, 'pc')"
                      @deleteItem="clickDeleteItem"></card-temporary-pc>

      <div v-if="service_list && service_list.length === 0" class="empty-view2">임시저장된 서비스가 없습니다</div>
    </div>
    <!-- mobile -->
    <div class="mobile container">
      <div class="h7 main" style="padding:24px 0 16px 0">퀵빌더 임시저장 서비스</div>

      <card-temporary v-for="(service, s_idx) in computedList"
                      :key="`service-${s_idx}`" :card="service"
                      @create="clickCreate(service)"
                      @edit="step=> clickEdit(service, step)"
                      @delete="clickDelete(service, s_idx, 'mobile')"
                      @deleteItem="clickDeleteItem"></card-temporary>

      <div v-if="service_list && service_list.length === 0" class="empty-view">임시저장된 서비스가 없습니다</div>
    </div>

    <div class="temporary-delete-modal">
      <sweet-modal overlay-theme="dark" ref="temporaryDeleteModal" :width="564"
                   :hide-close-button="true">
        <div class="body1" style="padding:48px 0">임시저장 내역을 삭제하시면 복구할 수 없습니다. <br> 삭제하시겠습니까?</div>
        <div class="flex-between body2-medium" style="padding:16px 16px 0">
          <button class="button" style="width:50%;height:48px;" @click="$refs.temporaryDeleteModal.close()">취소</button>
          <button class="button is-primary margin-left-16"
                  style="width:50%;height:48px;"
                  @click="deleteData('pc')">삭제하기</button>
        </div>
      </sweet-modal>
    </div>

    <div class="temporary-delete-modal-mobile">
      <sweet-modal overlay-theme="dark" ref="temporaryDeleteModalMobile" width="343px"
                   :hide-close-button="true">
        <div class="body4">임시저장 내역을 삭제하시면 복구할 수 없습니다. <br> 삭제하시겠습니까?</div>
        <div class="flex-between body5-medium" style="padding:16px 16px 0;margin-top:22px">
          <button class="button" style="width:50%;height:36px;" @click="$refs.temporaryDeleteModalMobile.close()">취소</button>
          <button class="button is-primary margin-left-16"
                  style="width:50%;height:36px;"
                  @click="deleteData('mobile')">삭제하기</button>
        </div>
      </sweet-modal>
    </div>
  </div>
</template>
<script>
  import UserAPIMixin from "../../mixins/UserAPIMixin";
  import PageMixin from "../../mixins/PageMixin";
  import CardTemporary from "../component/CardTemporary";
  import CardTemporaryPc from "../component/CardTemporaryPc";
  import {SweetModal} from 'sweet-modal-vue';
  export default {
    name: "TemporaryStoragePage",
    components: {CardTemporaryPc, CardTemporary, SweetModal},
    mixins: [
      PageMixin,
      UserAPIMixin
    ],
    created() {
      this.login_required = true;
      this.getData();
    },
    data() {
      return {
        service_list: undefined,   // 디스플레이 용 구성
        services: [],       // 가져온 내용 원본
        selectedOption: 0,
        deleteServiceId: 0,
        deleteServiceIdx: 0
      }
    },
    methods: {
      getData() {
        this.setLoading('불러오는 중');
        this.request.user.get('ui/super_builder/temporary?status=0&ordering=-id').then(res=>{
          this.services = res.data;
          this.service_list = res.data.map(item => {
            let price = 0;
            if(item.service.conversions) {
              let arr = item.service.conversions;
              if(item.service.plugins) {
                arr = arr.concat(item.service.plugins);
              } else {
                item.service.plugins = [];
              }
              if(arr.length>0) price = arr.map(item => { return item.add_option ? item.option.price.price : item.price.price }).reduce((acc,cur) => { return acc + cur } );
            } else {
              item.service.conversions = [];
              item.service.plugins = [];
            }

            return {
              id: item.id,
              fullName: `${item.name} | ${item.key}.launchpack.co.kr`,
              name: item.name,
              key: item.key,
              conversions: item.service.conversions,
              plugins: item.service.plugins,
              price: price
            }
          });
          this.clearLoading();
        }).catch(()=>{
          this.clearLoading();
        });
      },
      clickDelete(service, idx, type) {
        this.deleteServiceId = service.id;
        this.deleteServiceIdx = idx;
        if(type==='pc') this.$refs.temporaryDeleteModal.open();
        else this.$refs.temporaryDeleteModalMobile.open();
      },
      deleteData(type) {
        this.request.user.delete(`ui/super_builder/temporary/${this.deleteServiceId}`).then(()=>{
          this.service_list.remove(this.deleteServiceIdx);
          if(type==='pc') this.$refs.temporaryDeleteModal.close();
          else this.$refs.temporaryDeleteModalMobile.close();
        });
      },
      clickDeleteItem(res) {
        let service = this.service_list.filter(s=> { return s.key === res.service.key })[0];
        let list = service[res.type];
        for(let idx=0; idx<list.length; idx++) {
          let item = list[idx];
          if(item.name === res.item.name) {
            if(res.type === 'conversions' && service[res.type].length===1) {
              this.toast('주문전환은 최소 1개는 선택하셔야 합니다.');
              return;
            }
            service[res.type].remove(idx);
            break;
          }
        }
        service = this.services.filter(s=> { return s.key === res.service.key })[0];
        list = service.service[res.type];
        for(let idx=0; idx<list.length; idx++) {
          let item = list[idx];
          if(item.name === res.item.name) {
            if(res.type === 'conversions' && service[res.type].length===1) {
              this.toast('주문전환은 최소 1개는 선택하셔야 합니다.');
              return;
            }
            service[res.type].remove(idx);
            break;
          }
        }
      },
      clickEdit(service, step) {
        this.$store.commit('setTemporaryService', this.services.filter(item=>{ return item.id === service.id })[0]);
        let label = {
          1: '서비스팩 변경',
          2: '플러그인 변경',
          3: '디자인 편집'
        };
        this.routeGa('', '퀵빌더 임시저장 서비스', label[step]);
        this.$router.push(`/quickbuilder?step=${step}`);
      },
      clickCreate(service) {
        let temp_service = this.services.filter(item=>{ return item.id === service.id })[0];
        temp_service.service.price = service.price;

        this.$store.commit('setBasket', {
          type: 'quickbuilder',
          name: this.user.name,
          phone: this.user.phone,
          email: this.user.email,
          title: service.name,
          price: service.price + (service.price/10),
          option_type: 1,
          service: temp_service
        });

        this.routeGa('', '퀵빌더 임시저장 서비스', '개설하기');
        this.$router.push('/order');
      }
    },
    computed: {
      computedList() {
        let list = this.service_list;
        if(this.selectedOption === 0) {
          return list;
        } else {
          return list.filter(item => { return item.id === this.selectedOption });
        }
      },
      options() {
        if(!this.service_list) {
          return [];
        }
        let options = this.service_list.map(item => {
          return {
            id: item.id,
            name: item.name.split('|')[0]
          };
        });
        options.splice(0,0, {
          id: 0,
          name: '임시 저장 목록 전체보기'
        });
        return options;
      }
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .del
    margin-left 12px
    padding 6px 12px
    vertical-align bottom
</style>

<style lang="stylus">
  .temporary-delete-modal-mobile .sweet-modal.is-mobile-fullscreen
  .inquiry-reg-modal-mobile .sweet-modal.is-mobile-fullscreen
    top 50% !important
    left 50% !important
    transform translate(-50%, -50%) !important
  .inquiry-reg-modal-mobile .sweet-modal.is-mobile-fullscreen
    height 512px
  .temporary-delete-modal-mobile .sweet-modal.is-mobile-fullscreen
    height 182px

  .temporary-delete-modal-mobile .sweet-content
    padding-top 48px !important

  .temporary-delete-modal .sweet-content
    padding-top 32px !important

  .temporary-delete-modal-mobile .sweet-content
    padding-bottom 12px !important
</style>
